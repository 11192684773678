import { v4 } from 'uuid';

export const LOCALSTORAGE_DEVICE_ID_KEY = 'usdoku:pen';

function pad(d: number): string {
  return `${d <= 9 ? '0' : ''}${d}`;
}

export const setDeviceId = (key: string) => {
  localStorage.setItem(LOCALSTORAGE_DEVICE_ID_KEY, key);
};

export const generateDeviceId = (campaignName?: string) => {
  const date = new Date();
  const shortYear = date.getFullYear().toString().substring(2);
  const dateInfo = `${pad(date.getMonth() + 1)}${shortYear}`;

  const key = [campaignName, v4(), dateInfo].filter((x) => !!x).join('-');
  return key;
};

export const _readDeviceId = () => {
  return localStorage.getItem(LOCALSTORAGE_DEVICE_ID_KEY);
};

export const deviceId = () => {
  const current = _readDeviceId();
  if (current == null) {
    const newId = generateDeviceId();
    setDeviceId(newId);
    return newId;
  }
  return current;
};
